<template>
    <div class="workLog-container">
        <div v-if="!followRecordList || !followRecordList.length">暂无记录</div>
        <div v-else class="record-container">
            <div>
                <div v-for="(item, index) in followRecordList" :key="index + 'one'">
                    <el-timeline>
                        <el-timeline-item>
                            <template slot="dot">
                                <div class="timelineItem-date">{{item.date}}</div>
                            </template>
                            <div><br /><br /></div>
                        </el-timeline-item>
                        <el-timeline-item v-for="(itemTwo, indexTwo) in item.list" :key="indexTwo + 'two'">
                            <template slot="dot">
                                <div class="timelineItem-dot" :style="itemTwo.type != 'feedbackWorkOrderTask' && itemTwo.type != 'rollbackTask'  ? 'background-color: #1890ff' : 'background-color: #FE4C4E'"></div>
                            </template>
                            <div>
                                <div class="name-and-time"><div>{{itemTwo.nick_name}}</div><div>{{itemTwo.time}}</div></div>
                                <div class="timelineItem-detail" :style="itemTwo.type != 'feedbackWorkOrderTask' ? 'background-color: #1890ff19' : 'background-color: #FE4C4E19'">
                                    
                                    <div v-if="itemTwo.type == 'feedbackWorkOrderTask'">
                                        <div style="color:#FE4C4E">【工单反馈】</div>
                                        <div class="detailItem">反馈内容：<div class="detailItem-content lianjie"> {{itemTwo.value.feedback}}</div></div>
                                        <div class="detailItem">通知人：<div class="detailItem-content"> {{itemTwo.value.snedNames}}</div></div>
                                    </div>
                                    <div v-else-if="itemTwo.type == 'createWorkOrderTask'">
                                        <div v-if="itemTwo.value.workTypeId == 1 || itemTwo.value.workTypeId == 2" style="color:#1890ff">【创建工单】</div>
                                        <div class="detailItem" v-if="itemTwo.value.workTypeId == 1">任务 —<div class="detailItem-content"> {{itemTwo.value.workTypeName}}</div></div>
                                        <div class="detailItem" v-else-if="itemTwo.value.workTypeId == 2">审批 —<div class="detailItem-content"> {{itemTwo.value.workTypeName}}</div></div>
                                        <div v-else>
                                            <div style="color:#1890ff">【发起调价审批】</div>
                                            <div class="detailItem">审批工单名称：<div class="detailItem-content"> {{itemTwo.value.workTypeName}}</div></div>
                                            <div class="detailItem">优先级：<div class="detailItem-content"> {{itemTwo.value.priority == 1 ? '低' : itemTwo.value.priority == 2 ? '中' : itemTwo.value.priority == 3 ? '高' : '紧急' }}</div></div>
                                            <div class="detailItem">计划开始时间：<div class="detailItem-content">{{ DateTimeTransform(itemTwo.value.planStartTime) }}</div></div>
                                            <div class="detailItem">计划结束时间：<div class="detailItem-content">{{ DateTimeTransform(itemTwo.value.planEndTime) }}</div></div>
                                            <div class="detailItem">描述：<div class="detailItem-content lianjie">{{ itemTwo.value.remark || '--' }}</div></div>
                                            <div class="detailItem">附件：
                                                <div class="detailItem-content">
                                                    <div class="common-uploadBnList-container">
                                                        <div v-if="itemTwo.value&&itemTwo.value.fileList&&itemTwo.value.fileList.length" class="fileList-container">
                                                            <div v-for="(itemThree, indexThree) in itemTwo.value.fileList" :key="indexThree + 'three1'" class="fileList-item">
                                                                <div class="item-name">
                                                                    <common-picture :fileUrl="itemThree.url" :fileType="itemThree.type" :fileName="itemThree.name"></common-picture>
                                                                    <br/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>【调价信息】</div>
                                            <div class="detailItem">订单调价编号：<div class="detailItem-content"> {{itemTwo.value.workTypeName}}</div></div>
                                            <div class="detailItem">改价类型：<div class="detailItem-content"> {{itemTwo.value.relationParam.type == 0 ? '加班费' : itemTwo.value.type == 1 ? '咨询量差价' : itemTwo.value.type == 2 ? '增减坐席' : itemTwo.value.type == 3 ? '服务扣款' : itemTwo.value.type == 5 ? '服务对接群' : itemTwo.value.type == 4 ? '其他' :  '--'}}</div></div>
                                            <div class="detailItem">店铺：<div class="detailItem-content"> {{itemTwo.value.relationParam.workTypeName}}</div></div>
                                            <div class="detailItem">时间段：<div class="detailItem-content"> {{itemTwo.value.relationParam.startTime}} - {{itemTwo.value.endTime}}</div></div>
                                            <div class="detailItem">子账号数量：<div class="detailItem-content"> {{itemTwo.value.relationParam.agreeSubNum}}</div></div>
                                            <div class="detailItem">改价金额：<div class="detailItem-content"> {{itemTwo.value.relationParam.changeMoneny}}</div></div>
                                            <div class="detailItem">改价结果：<div class="detailItem-content"> {{itemTwo.value.relationParam.result}}</div></div>
                                            <div class="detailItem">改价说明：<div class="detailItem-content"> {{itemTwo.value.relationParam.remark}}</div></div>
                                            <div class="detailItem">审批人：<div class="detailItem-content"> {{itemTwo.value.dealName}}</div></div>
                                            <div class="detailItem">抄送人：<div class="detailItem-content"> {{itemTwo.value.sendName}}</div></div>
                                        </div>
                                    </div>
                                    <div v-else-if="itemTwo.type == 'rollbackTask'">
                                        <div style="color:#FE4C4E">【工单驳回】</div>
                                        <div class="detailItem">【驳回位置】： <div class="detailItem-content">{{itemTwo.value.flowName}}</div></div>
                                        <div class="detailItem">说明：<div class="detailItem-content  lianjie"> {{itemTwo.value.finishDescribe || '--'}}</div></div>
                                        <div class="detailItem">附件：
                                            <div class="detailItem-content">
                                                <div class="common-uploadBnList-container">
                                                    <div v-if="itemTwo.value.fileList&&itemTwo.value.fileList.length" class="fileList-container">
                                                        <div v-for="(itemThree, indexThree) in itemTwo.value.fileList" :key="indexThree + 'three'" class="fileList-item">
                                                            <div class="item-name">
                                                                <common-picture :fileUrl="itemThree.url" :fileType="itemThree.type" :fileName="itemThree.name"></common-picture>
                                                                <br/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        --
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else-if="itemTwo.type == 'updateWorkOrderTask'">
                                        <div style="color:#1890ff">【工单编辑】</div>
                                        <div class="detailItem">原本信息</div>
                                        <div class="detailItem" v-if="itemTwo.value.old.taskName != itemTwo.value.new.taskName">工单名称：<div class="detailItem-content">{{itemTwo.value.old.taskName || '--'}}</div></div>
                                        <div class="detailItem" v-if="itemTwo.value.old.priority != itemTwo.value.new.priority">优先级：<div class="detailItem-content">{{itemTwo.value.old.priority == 1 ? '低' : itemTwo.value.old.priority == 2 ? '中' : itemTwo.value.old.priority == 3 ? '高' : '紧急' }}</div></div>
                                        <div class="detailItem" v-if="itemTwo.value.old.planStartTime != itemTwo.value.new.planStartTime">计划开始时间：<div class="detailItem-content">{{ DateTimeTransform(itemTwo.value.old.planStartTime) }}</div></div>
                                        <div class="detailItem" v-if="itemTwo.value.old.planEndTime != itemTwo.value.new.planEndTime">计划结束时间：<div class="detailItem-content">{{ DateTimeTransform(itemTwo.value.old.planEndTime) }}</div></div>
                                        <div class="detailItem" v-if="itemTwo.value.old.remark != itemTwo.value.new.remark">描述：<div class="detailItem-content lianjie">{{ itemTwo.value.old.remark || '--' }}</div></div>
                                        <div class="detailItem" v-if="itemTwo.value.old.remarkFileName != itemTwo.value.new.remarkFileName">附件：
                                            <div class="detailItem-content">
                                                <div class="common-uploadBnList-container">
                                                    <div v-if="itemTwo.value&&itemTwo.value.fileList&&itemTwo.value.fileList.length" class="fileList-container">
                                                        <div v-for="(itemThree, indexThree) in itemTwo.value.fileList" :key="indexThree + 'three1'" class="fileList-item">
                                                            <div class="item-name">
                                                                <common-picture :fileUrl="itemThree.url" :fileType="itemThree.type" :fileName="itemThree.name"></common-picture>
                                                                <br/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="detailItem" v-if="itemTwo.value.old.sendName != itemTwo.value.new.sendName">抄送人：<div class="detailItem-content"> {{itemTwo.value.old.sendName}}</div></div>
                                        <div class="detailItem" v-if="itemTwo.value.old.workTypeId == 1 && itemTwo.value.old.chargeName != itemTwo.value.new.chargeName">负责人：<div class="detailItem-content"> {{itemTwo.value.old.chargeName || '--'}}</div></div>
                                        <div v-for="(itemThree, indexThree) in itemTwo.value.old.flowList" :key="indexThree + 'three2'">
                                            <br v-if="isEdit(itemThree, itemTwo.value.new.flowList[indexThree])"/>
                                            <div v-if="isEdit(itemThree, itemTwo.value.new.flowList[indexThree])">
                                                <div class="detailItem" v-if="numChina[itemThree.sort]">第{{numChina[itemThree.sort]}}步</div>
                                                <div class="detailItem" v-else>关闭工单</div>
                                                <div class="detailItem" v-if="itemTwo.value.old.workTypeId == 1">任务名称：<div class="detailItem-content"> {{itemThree.taskFlowName}}</div></div>
                                                <div class="detailItem" v-if="itemTwo.value.old.workTypeId == 1">任务描述：<div class="detailItem-content lianjie"> {{itemThree.taskFlowDescribe}}</div></div>
                                                <div class="detailItem">处理人：<div class="detailItem-content"> {{itemThree.dealName || '--'}}</div></div>
                                                <div class="detailItem" v-if="itemTwo.value.old.workTypeId == 1">说明：<div class="detailItem-content lianjie"> {{itemThree.finishDescribe || '--'}}</div></div>
                                                <div class="detailItem" v-if="itemTwo.value.old.workTypeId == 1">附件：
                                                    <div class="detailItem-content">
                                                        <div class="common-uploadBnList-container">
                                                            <div v-if="itemThree.fileList&&itemThree.fileList.length" class="fileList-container">
                                                                <div v-for="(itemFour, indexFour) in itemTwo.fileList" :key="indexFour + 'four'" class="fileList-item">
                                                                    <div class="item-name">
                                                                        <common-picture :fileUrl="itemThree.url" :fileType="itemThree.type" :fileName="itemThree.name"></common-picture>
                                                                        <br/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                        </div>
                                        <br/>
                                        <div class="blue-line"></div>
                                        <br/>
                                        <div class="detailItem">修改后信息</div>
                                        <div class="detailItem" v-if="itemTwo.value.old.taskName != itemTwo.value.new.taskName">工单名称：<div class="detailItem-content">{{itemTwo.value.new.taskName || '--'}}</div></div>
                                        <div class="detailItem" v-if="itemTwo.value.old.priority != itemTwo.value.new.priority">优先级：<div class="detailItem-content">{{itemTwo.value.new.priority == 1 ? '低' : itemTwo.value.new.priority == 2 ? '中' : itemTwo.value.new.priority == 3 ? '高' : '紧急' }}</div></div>
                                        <div class="detailItem" v-if="itemTwo.value.old.planStartTime != itemTwo.value.new.planStartTime">计划开始时间：<div class="detailItem-content">{{ DateTimeTransform(itemTwo.value.new.planStartTime) }}</div></div>
                                        <div class="detailItem" v-if="itemTwo.value.old.planEndTime != itemTwo.value.new.planEndTime">计划结束时间：<div class="detailItem-content">{{ DateTimeTransform(itemTwo.value.new.planEndTime) }}</div></div>
                                        <div class="detailItem" v-if="itemTwo.value.old.remark != itemTwo.value.new.remark">描述：<div class="detailItem-content lianjie">{{ itemTwo.value.new.remark || '--' }}</div></div>
                                        <div class="detailItem" v-if="itemTwo.value.old.remarkFileName != itemTwo.value.new.remarkFileName">附件：
                                            <div class="detailItem-content">
                                                <div class="common-uploadBnList-container">
                                                    <div v-if="itemTwo.value&&itemTwo.value.fileList&&itemTwo.value.fileList.length" class="fileList-container">
                                                        <div v-for="(itemThree, indexThree) in itemTwo.value.fileList" :key="indexThree + 'three3'" class="fileList-item">
                                                            <div class="item-name">
                                                                <common-picture :fileUrl="itemThree.url" :fileType="itemThree.type" :fileName="itemThree.name"></common-picture>
                                                                <br/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="detailItem" v-if="itemTwo.value.old.sendName != itemTwo.value.new.sendName">抄送人：<div class="detailItem-content"> {{itemTwo.value.new.sendName}}</div></div>
                                        <div class="detailItem" v-if="itemTwo.value.new.workTypeId == 1 && itemTwo.value.old.chargeName != itemTwo.value.new.chargeName">负责人：<div class="detailItem-content"> {{itemTwo.value.new.chargeName || '--'}}</div></div>
                                        <div v-for="(itemThree, indexThree) in itemTwo.value.new.flowList" :key="indexThree + 'three4'">
                                            <br v-if="isEdit(itemThree, itemTwo.value.old.flowList[indexThree])"/>
                                            <div v-if="isEdit(itemThree, itemTwo.value.old.flowList[indexThree])">
                                                <div class="detailItem" v-if="numChina[itemThree.sort]">第{{numChina[itemThree.sort]}}步</div>
                                                <div class="detailItem" v-else>关闭工单</div>
                                                <div class="detailItem" v-if="itemTwo.value.new.workTypeId == 1">任务名称：<div class="detailItem-content"> {{itemThree.taskFlowName}}</div></div>
                                                <div class="detailItem" v-if="itemTwo.value.new.workTypeId == 1">任务描述：<div class="detailItem-content lianjie"> {{itemThree.taskFlowDescribe}}</div></div>
                                                <div class="detailItem">处理人：<div class="detailItem-content"> {{itemThree.dealName || '--'}}</div></div>
                                                <div class="detailItem" v-if="itemTwo.value.old.workTypeId == 1">说明：<div class="detailItem-content lianjie"> {{itemThree.finishDescribe || '--'}}</div></div>
                                                <div class="detailItem" v-if="itemTwo.value.old.workTypeId == 1">附件：
                                                    <div class="detailItem-content">
                                                        <div class="common-uploadBnList-container">
                                                            <div v-if="itemThree.fileList&&itemThree.fileList.length" class="fileList-container">
                                                                <div v-for="(itemFour, indexFour) in itemTwo.fileList" :key="indexFour + 'four'" class="fileList-item">
                                                                    <div class="item-name">
                                                                        <common-picture :fileUrl="itemThree.url" :fileType="itemThree.type" :fileName="itemThree.name"></common-picture>
                                                                        <br/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                        <!-- <div class="detailItem">原本信息：工单名称：<div class="detailItem-content">{{itemTwo.value.old.taskName}}</div></div>
                                        <div class="detailItem">修改后信息：工单名称：<div class="detailItem-content"> {{itemTwo.value.new.taskName}}</div></div> -->
                                    </div>
                                    <div v-else-if="itemTwo.type == 'closeWorkOrderTask' && itemTwo.value.workTypeId == 1">
                                        <div style="color:#1890ff">【关闭工单】</div>
                                         <div class="detailItem">关闭时间：<div class="detailItem-content">{{item.date}} {{itemTwo.time}}</div></div>
                                        <div class="detailItem">说明：<div class="detailItem-content"> {{itemTwo.value.terminationRemark}}</div></div>
                                    </div>
                                    <div v-else-if="itemTwo.type == 'closeWorkOrderTask' && itemTwo.value.workTypeId == 2">
                                        <div style="color:#1890ff" v-if="itemTwo.value.createId == itemTwo.value.terminationId">【工单撤销】</div>
                                        <div style="color:#1890ff" v-if="itemTwo.value.createId != itemTwo.value.terminationId">【工单拒绝】</div>
                                        <div class="detailItem" v-if="itemTwo.value.createId == itemTwo.value.terminationId">撤销时间：<div class="detailItem-content">{{item.date}} {{itemTwo.time}}</div></div>
                                        <div class="detailItem" v-if="itemTwo.value.createId != itemTwo.value.terminationId">拒绝时间：<div class="detailItem-content">{{item.date}} {{itemTwo.time}}</div></div>
                                        <div class="detailItem">说明：<div class="detailItem-content lianjie"> {{itemTwo.value.terminationRemark}}</div></div>
                                    </div>
                                    <div v-else-if="itemTwo.type == 'dealWorkOrderTask' && itemTwo.value.workTypeId == 1">
                                        <div style="color:#1890ff">【任务处理】</div>
                                        <div class="detailItem" v-if="itemTwo.value.sort == 999">关闭工单</div>
                                        <div class="detailItem" v-else>第{{numChina[itemTwo.value.sort]}}步：<div class="detailItem-content"> {{itemTwo.value.taskFlowName}}</div></div>
                                        <div class="detailItem">任务状态：<div class="detailItem-content"> {{itemTwo.value.status == 1 ? '已完成' : "--"}}</div></div>
                                        <div class="detailItem">完成时间：<div class="detailItem-content"> {{DateTimeTransform(itemTwo.value.finishTime) || "--"}}</div></div>
                                        <div class="detailItem">说明：<div class="detailItem-content lianjie"> {{itemTwo.value.finishDescribe ? itemTwo.value.finishDescribe + '' : '--'}}</div></div>
                                        <div class="detailItem">附件：
                                            <div class="detailItem-content">
                                                <div class="common-uploadBnList-container">
                                                    <div v-if="itemTwo.value&&itemTwo.value.fileList&&itemTwo.value.fileList.length" class="fileList-container">
                                                        <div v-for="(itemThree, indexThree) in itemTwo.value.fileList" :key="indexThree + 'three5'" class="fileList-item">
                                                            <div class="item-name">
                                                                <common-picture :fileUrl="itemThree.url" :fileType="itemThree.type" :fileName="itemThree.name"></common-picture>
                                                                <br/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else-if="itemTwo.type == 'dealWorkOrderTask' && itemTwo.value.workTypeId == 2">
                                        <div style="color:#1890ff">【审批处理】</div>
                                        <div class="detailItem" v-if="itemTwo.value.sort == 99">关闭工单</div>
                                        <div class="detailItem" v-else>第{{numChina[itemTwo.value.sort]}}步：<div class="detailItem-content"> {{itemTwo.value.taskFlowName}}</div></div>
                                        <div class="detailItem">审批状态：<div class="detailItem-content"> {{itemTwo.value.status == 1 ? '同意' : "拒绝"}}</div></div>
                                        <div class="detailItem">完成时间：<div class="detailItem-content"> {{DateTimeTransform(itemTwo.value.finishTime) || "--"}}</div></div>
                                        <div class="detailItem">说明：<div class="detailItem-content lianjie"> {{itemTwo.value.finishDescribe || '--'}}</div></div>
                                        <div class="detailItem">附件：
                                            <div class="detailItem-content">
                                                <div class="common-uploadBnList-container">
                                                    <div v-if="itemTwo.value&&itemTwo.value.fileList&&itemTwo.value.fileList.length" class="fileList-container">
                                                        <div v-for="(itemThree, indexThree) in itemTwo.value.fileList" :key="indexThree + 'three6'" class="fileList-item">
                                                            <div class="item-name">
                                                                <common-picture :fileUrl="itemThree.url" :fileType="itemThree.type" :fileName="itemThree.name"></common-picture>
                                                                <br/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else-if="itemTwo.type == 'dealWorkOrderTask'">
                                        <div style="color:#1890ff">【审批处理】</div>
                                        <div class="detailItem" v-if="itemTwo.value.sort == 99">关闭工单</div>
                                        <div class="detailItem" v-else>第{{numChina[itemTwo.value.sort]}}步：<div class="detailItem-content"> {{itemTwo.value.taskFlowName}}</div></div>
                                        <div class="detailItem">审批状态：<div class="detailItem-content"> {{itemTwo.value.status == 1 ? '同意' : "拒绝"}}</div></div>
                                        <div class="detailItem">完成时间：<div class="detailItem-content"> {{DateTimeTransform(itemTwo.value.finishTime) || "--"}}</div></div>
                                        <div class="detailItem">说明：<div class="detailItem-content lianjie"> {{itemTwo.value.finishDescribe || '--'}}</div></div>
                                        <div class="detailItem">附件：
                                            <div class="detailItem-content">
                                                <div class="common-uploadBnList-container">
                                                    <div v-if="itemTwo.value&&itemTwo.value.fileList&&itemTwo.value.fileList.length" class="fileList-container">
                                                        <div v-for="(itemThree, indexThree) in itemTwo.value.fileList" :key="indexThree + 'three6'" class="fileList-item">
                                                            <div class="item-name">
                                                                <common-picture :fileUrl="itemThree.url" :fileType="itemThree.type" :fileName="itemThree.name"></common-picture>
                                                                <br/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-timeline-item>
                        <el-timeline-item style="display:none">
                            <template slot="dot">
                                <div class=""></div>
                            </template>
                            <div></div>
                        </el-timeline-item>
                    </el-timeline>
                </div>
            </div>
            <div>
                <br />
                <br />
            </div>
        </div>
    </div>
</template>
<script>
import { DateTransform, Config, DateTimeTransform } from "../../../utils/index.js"
import CommonPicture from "../../../components/common/commonPicture.vue"
export default {
    components: {CommonPicture},
    props: ["followRecordList"],
    name: 'workLog',
    data() {
        return {
            Config,
            DateTransform,
            DateTimeTransform,
            numChina: ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十']
        }
    },
    methods: {
        isEdit(oldValue, newValue) {
            return JSON.stringify(oldValue) != JSON.stringify(newValue)
        }
    }
}
</script>
<style lang="less" scoped>
.workLog-container {
    flex: 1;
    overflow: hidden;
    .record-container {
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        /deep/.el-timeline-item__tail {
            left: 50px;
        }
        .timelineItem-date {
            width: 100px;
            height: 48px;
            line-height: 48px;
            white-space: nowrap;
            color: #999;
            font-size: 12px;
            text-align: center;
            background: #fff;
        }
        .timelineItem-dot {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 4px solid #eff6ff;
            background: #1890ff;
            font-size: 14px;
            line-height: 12px;
            text-align: center;
            position: relative;
            left: 41px;
            
        }
        .name-and-time {
            display: flex;
            justify-content: space-between;
            position: relative;
            margin-left: 42px;
            color: #333;
            font-size: 12px;
            padding-bottom: 16px;
        }
        .timelineItem-detail {
            border-radius: 6px;
            position: relative;
            margin-left: 42px;
            padding: 16px;
            .detailItem {
                display: flex;
                margin-top: 8px;
                white-space: nowrap;
                .detailItem-content {
                    flex: 1;
                    white-space: pre-wrap;
                    word-break: break-all;
                }
            }
            
        }
        
    }
    .blue-line {
        height: 1px;
        width: 100%;
        background: #1890ff;
    }
}
</style>